/* eslint-disable indent */
import api from '@/services/api';
import AppError from '@/utils/appError';

const resource = 'contratos/pme';

const obterContrato = async (guid, previa) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_CONTRATOS_API_KEY } };

    const response = await api.post(`${resource}/download`,{
        guid, previa
    }, config);
    return response;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const atualizarContrato = async (guid, atualizarContratoMarcaDagua) => {
  const marcaDagua = atualizarContratoMarcaDagua === true;
  const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_CONTRATOS_API_KEY } };
  await api.post(`${resource}`, {
    marcaDagua: marcaDagua,
    guid
  }, config);
};

export default {
  obterContrato,
  atualizarContrato,
};
