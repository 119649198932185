<template>
  <div>
    <v-app-bar color="primary" dark fixed>
      <v-container class="d-flex align-center pa-0">
        <v-btn class="mr-3" small icon dark @click="$emit('closedialog')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title class="mt-1">
          <div>
            <h1 class="title font-weight-bold" style="line-height: 1.3">
              Dados Vigência
            </h1>
            <h3 class="caption ma-0" style="line-height: 1.2">
              Informações sobre a vigência selecionada
            </h3>
          </div>
        </v-toolbar-title>
      </v-container>
    </v-app-bar>

    <div class="header-height"></div>

    <v-container class="pa-5 pt-0">
      <v-row no-gutters>
        <v-col cols="12" class="mb-2 mt-2">
          <b>Data Vigência</b> <br />
          {{ dataVigencia }}
        </v-col>
      </v-row>
      <v-row no-gutters class="mb-2">
        <v-col cols="12">
          <b>Data fechamento aceitação</b> <br />
          {{ dataFechamentoAceitacao }}
        </v-col>
      </v-row>
      <v-row no-gutters class="mb-2">
        <v-col cols="12">
          <b>Tempo para transmissão</b> <br />
          {{ propostaState?.tempoParaTransmissao || "--" }}
        </v-col>
      </v-row>
    </v-container>

    <v-footer fixed v-if="showEditButton">
      <v-col class="text-center pa-1" cols="12">
        <v-btn large color="primary" class="secundaryBtn--text" @click="edit" :loading="loading">
          Editar dados
        </v-btn>
      </v-col>
    </v-footer>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import filters from "@/filters";
import { Etapas } from "@/utils/etapas";
import planoService from '@/services/planoService';

export default {
  props: {
    showBotao: Boolean,
    etapa: Object,
    validarEdicao: Function,
  },
  computed: {
    ...mapState({
      propostaState: (state) => state.PME,
    }),
    dataVigencia() {
      if (this.propostaState.vigencia) {
        return filters.convertDateOutput(this.propostaState.vigencia);
      }
      return "--";
    },
    dataFechamentoAceitacao() {
      if (this.propostaState.dataFechamentoAceitacao) {
        return filters.convertDateOutput(this.propostaState.dataFechamentoAceitacao);
      }
      return "--";
    },
    showEditButton() {
      this.carregarEtapa();
      return (this.etapa && this.etapa.ordem >= this.etapaVigencia.ordem && this.showBotao);
    },
  },
  data: () => ({
    etapaVigencia: {},
    planoNaoComercializado: false,
    loading: true,
  }),
  methods: {
    ...mapActions({
      setBlnContinuar: "cadastroProposta/setBlnContinuar",
    }),
    carregarEtapa() {
      const objEtapas = new Etapas();
      this.objEtapas = objEtapas;
      objEtapas.loadEtapas();
      this.etapaVigencia = objEtapas.getEtapaAtual("vigencia");
    },
    async edit() {
      const permiteEditar = await this.validarEdicao();
      if (!permiteEditar) {
        this.$emit('showmsgeditar');
        this.$emit('closedialog');
        return;
      }

      this.setBlnContinuar({
        flag: true, page: 3, isEditing: true, isEditingVigencia: true, planoNaoComercializado: this.planoNaoComercializado,
      });
      this.$router.push({ name: "areaLogada.criarProposta" });
    },
    async carregarDadosPlano() {
      try {
        if (!(this.propostaState.plano && this.propostaState.plano.idProdutoFatura)) return;
        const input = {
          idProdutoFatura: this.propostaState.plano.idProdutoFatura,
          cpfCnpjCorretora: this.propostaState.corretora.cpfCnpj,
          portabilidade: this.propostaState.possuiPortabilidade,
        };
        const { data } = await planoService.getPlano({ input, administradora: this.propostaState.administradora });
        if (!data.length) {
          this.planoNaoComercializado = true;
        }
      } catch (error) {
        // console.log('error', error);
        // this.$root.$snackBar.open({
        //   color: 'error',
        //   message: 'Erro ao buscar dados do plano, tente novamente mais tarde',
        // });
      } finally {
        this.loading = false;
      }
    },
  },
  async mounted() {
    await this.carregarDadosPlano();
  },
};
</script>

<style lang="scss" scoped>
.header-height {
  padding: 20px;
  height: 70px;
}
</style>
