var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        { attrs: { color: "primary", dark: "", fixed: "" } },
        [
          _c(
            "v-container",
            { staticClass: "d-flex align-center pa-0" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-3",
                  attrs: { small: "", icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("closedialog")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
              _c("v-toolbar-title", { staticClass: "mt-1" }, [
                _c("div", [
                  _c(
                    "h1",
                    {
                      staticClass: "title font-weight-bold mb-1",
                      staticStyle: { "line-height": "1" },
                    },
                    [_vm._v(" Dados Empresa ")]
                  ),
                  _c(
                    "h3",
                    {
                      staticClass: "caption ma-0",
                      staticStyle: { "line-height": "1" },
                    },
                    [_vm._v(" Informações pessoais e endereço ")]
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "header-height" }),
      _c(
        "v-container",
        { staticClass: "pa-5 pb-5 pt-0 elevation-0" },
        [
          _c(
            "v-expansion-panels",
            {
              staticClass: "pb-5 elevation-0",
              attrs: { multiple: "" },
              model: {
                value: _vm.panel,
                callback: function ($$v) {
                  _vm.panel = $$v
                },
                expression: "panel",
              },
            },
            [
              _c(
                "v-expansion-panel",
                { staticClass: "elevation-0" },
                [
                  _c("v-expansion-panel-header", [
                    _c(
                      "h1",
                      {
                        staticClass:
                          "title font-weight-bold mb-1 primary--text",
                        staticStyle: { "line-height": "1" },
                      },
                      [_vm._v(" Dados Responsavel ")]
                    ),
                  ]),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c("v-divider", { staticClass: "mb-4" }),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "mb-2", attrs: { cols: "12" } },
                            [
                              _c("b", [_vm._v("Nome")]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (_vm.propostaState.empresa
                                      .representanteEmpresa &&
                                      _vm.propostaState.empresa
                                        .representanteEmpresa.nomeCompleto) ||
                                      "--"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "mb-2", attrs: { cols: "12" } },
                            [
                              _c("b", [_vm._v("Email")]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (_vm.propostaState.empresa
                                      .representanteEmpresa &&
                                      _vm.propostaState.empresa
                                        .representanteEmpresa.email) ||
                                      "--"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "mb-2", attrs: { cols: "6" } },
                            [
                              _c("b", [_vm._v("CPF")]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("cnpjCpfMask")(
                                      _vm.propostaState.empresa
                                        .representanteEmpresa &&
                                        _vm.propostaState.empresa
                                          .representanteEmpresa.cpf
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "v-col",
                            { staticClass: "mb-2", attrs: { cols: "6" } },
                            [
                              _c("b", [_vm._v("Data de Nascimento")]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("convertDateOutput")(
                                      _vm.propostaState.empresa
                                        .representanteEmpresa &&
                                        _vm.propostaState.empresa
                                          .representanteEmpresa.dataNascimento
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "mb-2", attrs: { cols: "12" } },
                            [
                              _c("b", [_vm._v("Nome da Mãe")]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (_vm.propostaState.empresa
                                      .representanteEmpresa &&
                                      _vm.propostaState.empresa
                                        .representanteEmpresa.nomeMae) ||
                                      "--"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "mb-2", attrs: { cols: "6" } },
                            [
                              _c("b", [_vm._v("Sexo")]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (_vm.propostaState.empresa
                                      .representanteEmpresa &&
                                      _vm.propostaState.empresa
                                        .representanteEmpresa.sexo) ||
                                      "--"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "mb-2", attrs: { cols: "12" } },
                            [
                              _c("b", [_vm._v("Telefone Principal")]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (_vm.propostaState.empresa
                                      .representanteEmpresa &&
                                      _vm.propostaState.empresa
                                        .representanteEmpresa.telefone) ||
                                      "--"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "mb-2", attrs: { cols: "12" } },
                            [
                              _c("b", [_vm._v("Telefone Opcional")]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (_vm.propostaState.empresa
                                      .representanteEmpresa &&
                                      _vm.propostaState.empresa
                                        .representanteEmpresa.celular) ||
                                      "--"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-expansion-panel",
                { staticClass: "elevation-0" },
                [
                  _c("v-expansion-panel-header", [
                    _c(
                      "h1",
                      {
                        staticClass:
                          "title font-weight-bold mb-1 primary--text",
                        staticStyle: { "line-height": "1" },
                      },
                      [_vm._v(" Dados da empresa ")]
                    ),
                  ]),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c("v-divider", { staticClass: "mb-4" }),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "mb-2", attrs: { cols: "6" } },
                            [
                              _c("b", [_vm._v("CNPJ")]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("cnpjCpfMask")(
                                      _vm.propostaState.empresa.dadosEmpresa &&
                                        _vm.propostaState.empresa.dadosEmpresa
                                          .cnpj
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "v-col",
                            { staticClass: "mb-2", attrs: { cols: "6" } },
                            [
                              _c("b", [_vm._v("CNAE")]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (_vm.propostaState.empresa.dadosEmpresa &&
                                      _vm.propostaState.empresa.dadosEmpresa
                                        .cnae) ||
                                      "--"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "mb-2", attrs: { cols: "6" } },
                            [
                              _c("b", [_vm._v("Razão Social")]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (_vm.propostaState.empresa.dadosEmpresa &&
                                      _vm.propostaState.empresa.dadosEmpresa
                                        .razaoSocial) ||
                                      "--"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "v-col",
                            { staticClass: "mb-2", attrs: { cols: "6" } },
                            [
                              _c("b", [_vm._v("Nome Fantasia")]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (_vm.propostaState.empresa.dadosEmpresa &&
                                      _vm.propostaState.empresa.dadosEmpresa
                                        .nomeFantasia) ||
                                      "--"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "mb-2", attrs: { cols: "6" } },
                            [
                              _c("b", [_vm._v("Inscrição Municipal")]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (_vm.propostaState.empresa.dadosEmpresa &&
                                      _vm.propostaState.empresa.dadosEmpresa
                                        .inscricaoMunicipal) ||
                                      "--"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "v-col",
                            { staticClass: "mb-2", attrs: { cols: "6" } },
                            [
                              _c("b", [_vm._v("Data Abertura")]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("convertDateOutput")(
                                      _vm.propostaState.empresa.dadosEmpresa &&
                                        _vm.propostaState.empresa.dadosEmpresa
                                          .dataAbertura
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", [
                    _c(
                      "h1",
                      {
                        staticClass:
                          "title font-weight-bold mb-1 primary--text",
                        staticStyle: { "line-height": "1" },
                      },
                      [_vm._v(" Endereço ")]
                    ),
                  ]),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c("v-divider", { staticClass: "mb-4" }),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "mb-2", attrs: { cols: "6" } },
                            [
                              _c("b", [_vm._v("CEP")]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (_vm.propostaState.empresa
                                      .enderecoEmpresa &&
                                      _vm.propostaState.empresa.enderecoEmpresa
                                        .cep) ||
                                      "--"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "mb-2", attrs: { cols: "6" } },
                            [
                              _c("b", [_vm._v("Cidade")]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (_vm.propostaState.empresa
                                      .enderecoEmpresa &&
                                      _vm.propostaState.empresa.enderecoEmpresa
                                        .cidade) ||
                                      "--"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "v-col",
                            { staticClass: "mb-2", attrs: { cols: "6" } },
                            [
                              _c("b", [_vm._v("UF")]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (_vm.propostaState.empresa
                                      .enderecoEmpresa &&
                                      _vm.propostaState.empresa.enderecoEmpresa
                                        .estado) ||
                                      "--"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "mb-2", attrs: { cols: "12" } },
                            [
                              _c("b", [_vm._v("Logradouro")]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (_vm.propostaState.empresa
                                      .enderecoEmpresa &&
                                      _vm.propostaState.empresa.enderecoEmpresa
                                        .logradouro) ||
                                      "--"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "mb-2", attrs: { cols: "12" } },
                            [
                              _c("b", [_vm._v("Número")]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (_vm.propostaState.empresa
                                      .enderecoEmpresa &&
                                      _vm.propostaState.empresa.enderecoEmpresa
                                        .numero) ||
                                      "--"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "mb-2", attrs: { cols: "12" } },
                            [
                              _c("b", [_vm._v("Bairro")]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (_vm.propostaState.empresa
                                      .enderecoEmpresa &&
                                      _vm.propostaState.empresa.enderecoEmpresa
                                        .bairro) ||
                                      "--"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showEditButton
        ? _c(
            "v-footer",
            { attrs: { fixed: "" } },
            [
              _c(
                "v-col",
                { staticClass: "text-center pa-1", attrs: { cols: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "secundaryBtn--text",
                      attrs: { large: "", color: "primary" },
                      on: { click: _vm.edit },
                    },
                    [_vm._v(" Editar dados ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }