var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        { attrs: { color: "primary", dark: "", fixed: "" } },
        [
          _c(
            "v-container",
            { staticClass: "d-flex align-center pa-0" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-3",
                  attrs: { small: "", icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("closedialog")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
              _c("v-toolbar-title", { staticClass: "mt-1" }, [
                _c("div", [
                  _c(
                    "h1",
                    {
                      staticClass: "title font-weight-bold",
                      staticStyle: { "line-height": "1.3" },
                    },
                    [_vm._v(" Dados Vigência ")]
                  ),
                  _c(
                    "h3",
                    {
                      staticClass: "caption ma-0",
                      staticStyle: { "line-height": "1.2" },
                    },
                    [_vm._v(" Informações sobre a vigência selecionada ")]
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "header-height" }),
      _c(
        "v-container",
        { staticClass: "pa-5 pt-0" },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c("v-col", { staticClass: "mb-2 mt-2", attrs: { cols: "12" } }, [
                _c("b", [_vm._v("Data Vigência")]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" " + _vm._s(_vm.dataVigencia) + " "),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mb-2", attrs: { "no-gutters": "" } },
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _c("b", [_vm._v("Data fechamento aceitação")]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" " + _vm._s(_vm.dataFechamentoAceitacao) + " "),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mb-2", attrs: { "no-gutters": "" } },
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _c("b", [_vm._v("Tempo para transmissão")]),
                _vm._v(" "),
                _c("br"),
                _vm._v(
                  " " +
                    _vm._s(_vm.propostaState?.tempoParaTransmissao || "--") +
                    " "
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showEditButton
        ? _c(
            "v-footer",
            { attrs: { fixed: "" } },
            [
              _c(
                "v-col",
                { staticClass: "text-center pa-1", attrs: { cols: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "secundaryBtn--text",
                      attrs: {
                        large: "",
                        color: "primary",
                        loading: _vm.loading,
                      },
                      on: { click: _vm.edit },
                    },
                    [_vm._v(" Editar dados ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }