var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mb-8 pb-8" },
    [
      _c(
        "v-app-bar",
        { attrs: { color: "primary", dark: "", fixed: "" } },
        [
          _c(
            "v-container",
            { staticClass: "d-flex align-center pa-0" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-3",
                  attrs: { small: "", icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("closedialog")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
              _c("v-toolbar-title", { staticClass: "mt-1" }, [
                _c("div", [
                  _c(
                    "h1",
                    {
                      staticClass: "title font-weight-bold mb-1",
                      staticStyle: { "line-height": "1" },
                    },
                    [_vm._v(" Dados Financeiros ")]
                  ),
                  _c(
                    "h3",
                    {
                      staticClass: "caption ma-0",
                      staticStyle: { "line-height": "1" },
                    },
                    [_vm._v(" Informações financeiras ")]
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "header-height" }),
      _c(
        "v-container",
        { staticClass: "pa-5 pt-0" },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c("v-col", { staticClass: "mb-2 mt-2", attrs: { cols: "12" } }, [
                _c("b", [_vm._v("Forma de pagamento")]),
                _vm._v(" "),
                _c("br"),
                _vm._v(
                  " " +
                    _vm._s(
                      (_vm.propostaState.empresa.pagamento &&
                      _vm.propostaState.empresa.pagamento.formaPagamento &&
                      _vm.propostaState.empresa.pagamento.formaPagamento.toUpperCase() ===
                        "BOLETO"
                        ? "Boleto Digital"
                        : "Débito Automático") || "--"
                    ) +
                    " "
                ),
              ]),
            ],
            1
          ),
          _vm.propostaState.empresa.pagamento.porcentagemTaxaAngariacao
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "mb-2 mt-2", attrs: { cols: "12" } },
                    [
                      _c(
                        "h1",
                        {
                          staticClass:
                            "title font-weight-bold mb-1 mt-4 primary--text",
                          staticStyle: { "line-height": "1" },
                        },
                        [_vm._v(" Tipo de Taxa de Angariação ")]
                      ),
                      _c("v-divider", { staticClass: "mb-2 mt-2" }),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "mb-2 mt-2", attrs: { cols: "6" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.propostaState.empresa.pagamento
                                      .porcentagemTaxaAngariacao
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.showEditButton
        ? _c(
            "v-footer",
            { attrs: { fixed: "" } },
            [
              _c(
                "v-col",
                { staticClass: "text-center pa-1", attrs: { cols: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "secundaryBtn--text",
                      attrs: { large: "", color: "primary" },
                      on: { click: _vm.edit },
                    },
                    [_vm._v(" Editar dados ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }