<template>
  <div>
    <v-app-bar color="primary" dark fixed>
      <v-container class="d-flex align-center pa-0">
        <v-btn class="mr-3" small icon dark @click="$emit('closedialog')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title class="mt-1">
          <div>
            <h1 class="title font-weight-bold mb-1" style="line-height: 1">
              Contrato
            </h1>
            <h3 class="caption ma-0" style="line-height: 1">
              Visualização de Contrato
            </h3>
          </div>
        </v-toolbar-title>
      </v-container>
    </v-app-bar>

    <div class="header-height"></div>
    <div v-if="loadingDocs" class="d-flex justify-center mt-10 pa-10">
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
    </div>
    <v-container v-else-if="contrato" class="pa-4 pt-0">
      <v-row>
        <div class="text-subtitle-1 font-weight-medium primary--text my-4 ml-2">
          Clique abaixo para fazer o download:
        </div>
      </v-row>
      <v-row>
        <v-col>
          <v-card
            class="
              pa-1
              d-flex
              float-left
              align-center
              justify-center
              mr-2
              p-relative
            "
            style="overflow: hidden"
            elevation="3"
            :height="230"
            :width="230"
          >
            <v-col v-if="pdf.loading">
              <v-row class="justify-center">
                <v-icon color="red" size="80">mdi-file-pdf</v-icon>
              </v-row>
              <v-row class="justify-center ml-2 mr-2">
                <v-progress-linear indeterminate color="red" height="2"> </v-progress-linear>
              </v-row>
            </v-col>
            <v-img v-else position="top" v-bind="$attrs" :src="`data:image/png;base64,${pdf.urlShow}`" @click="zoom(pdf.link)" style="cursor: pointer" alt></v-img>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <div v-else>
      <v-container v-if="!pdf.loading && !loadingDocs" class="pa-5 pt-0 mt-8">
        <v-row class="justify-center">
          <img :src="folder" width="180" height="180" />
        </v-row>
        <v-row>
          <v-col class="text-center pa-1 mt-5">
            <span class="primary--text">{{ aviso }}</span>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-footer fixed>
      <v-col class="text-right pa-1">
        <v-btn :disabled="pdf.loading || loadingDocs || !existePrevia || disableAtualizar" large color="primary" @click="atualizarContrato">
          Atualizar Contrato
        </v-btn>
      </v-col>
      <v-col class="text-left pa-1">
        <v-btn large color="primary" class="secundaryBtn--text" @click="$emit('closedialog')">
          Voltar
        </v-btn>
      </v-col>
    </v-footer>
  </div>
</template>

<script>
/* eslint-disable */
import contratoPmeService from "@/services/contratoPmeService";
import documentoService from "@/services/documentoService";
import { mapState } from "vuex";
import Folder from "@/assets/img/folder.svg";

export default {
  props: {
    showBotao: Boolean,
    anexarArquivos: Boolean,
    excluirArquivos: Boolean,
  },
  data() {
    return {
      aviso: "",
      ativo: {},
      disableAtualizar: false,
      existePrevia: false,
      beneficiarios: [],
      dialogDoc: false,
      paramEnviar: {},
      loadingDocs: false,
      pdf: { loading: true },
      subtitulo: "",
      contrato: "",
      componentKey: 0,
      folder: "",
    };
  },
  created() {
    this.folder = Folder;
  },
  async mounted() {
    await this.verificaSeExistePrevia();
    await this.buscaContrato();
  },
  watch: {
    immediate: true,
    deep: true,
  },
  computed: {
    ...mapState({
      propostaState: (state) => state.PME,
      dadosTitularState: (state) => state.cadastroProposta.titular,
    }),
    hasPreview() {
      return this.contrato.length > 0;
    },
  },
  methods: {
    zoom(file) {
      window.open(file);
    },
    getTipoAtualContrato() {
      const status = (this.propostaState.status || "").toUpperCase();
      let tipo = "CONTRATO";

      // FILA: EM ANDAMENTO
      if (["EM_DIGITACAO", ""].includes(status)) {
        tipo = "CONTRATO_MARCA_DAGUA";
      }

      // FILA: EM APROVAÇÃO
      if (["DEVOLVIDA", "EM_ANALISE", "CANCELADA"].includes(status)) {
        tipo = "CONTRATO_MARCA_DAGUA";
      }

      // FILA: HISTÓRICO
      if (["IMPLANTADA", "FINALIZADA", "NAO_RECOMENDADA"].includes(status)) {
        tipo = "CONTRATO";
      }

      return tipo;
    },
    async verificaSeExistePrevia() {
      this.loadingDocs = true;
      try {
        try {
          const { guid: guidProposta } = this.propostaState;
          const previa = await contratoPmeService.obterContrato(guidProposta, "PREVIA");

          this.existePrevia = previa !== undefined;
        } catch {
          this.existePrevia = false;
        }
        if (!this.existePrevia) {
          this.aviso = "Contrato aguardando assinatura.";
        }
      } finally {
        this.loadingDocs = false;
      }
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async buscaContrato(tentativas = 1) {
      let tentativa = 1;

      while (tentativa <= tentativas) {
        this.loadingDocs = true;
        let msgError = "";
        try {
          try {
            // Não exibe contrato em fila transmissão
            if ((this.propostaState.status || "").toUpperCase() === "FILA_TRANSMISSAO") {
              const msg = "Contrato em geração, tente novamente mais tarde.";

              this.aviso = msg;
              this.$root.$snackBar.open({
                color: "error",
                message: msg,
              });
              return;
            }

            const tipo = this.getTipoAtualContrato();

            if (tipo === "CONTRATO") {
              this.disableAtualizar = true;
            }

            if ((this.propostaState.status || "").toUpperCase() === "EM_DIGITACAO" && (this.propostaState.status || "").toUpperCase() !== "CONTRATO_ASSINADO") {
              msgError = "Contrato ainda não está disponivel.";
            } else {
              msgError = "Não foi possível carregar o contrato. Clique em atualizar";
            }

            const { guid: guidProposta } = this.propostaState;
            let contrato;

            try {
              contrato = await contratoPmeService.obterContrato(guidProposta, tipo);
            } catch (err) {
              tentativa += 1;
              if (tentativa > tentativas) {
                throw err;
              }

              await this.sleep(10000); // Aguarda 10segundos antes de buscar novamente
              continue;
            }

            this.contrato = contrato.data.data.url;

            const pdf = {
              loading: false,
              link: this.contrato,
              urlShow: "",
            };

            pdf.urlShow = await this.getFirstPage(pdf);
            this.pdf = pdf;
          } catch (error) {
            this.$root.$snackBar.open({
              color: "error",
              message: msgError,
            });
            this.aviso = msgError;
            this.loadingDocs = false;
          }
        } finally {
          this.loadingDocs = false;
          this.pdf.loading = false;
        }
        break;
      }
    },
    async atualizarContrato() {
      const resp = await this.$root.$confirmdialog.open(
        "Atenção",
        `Este processo pode levar por volta de 1 minuto para processar.

        Deseja prosseguir?`
      );

      if (resp) {
        const tipo = this.getTipoAtualContrato();

        this.loadingDocs = true;
        const atualizarContratoMarcaDagua = tipo === "CONTRATO_MARCA_DAGUA";
        await contratoPmeService.atualizarContrato(this.propostaState.guid, atualizarContratoMarcaDagua);
        await this.sleep(3000);
        const tentativas = atualizarContratoMarcaDagua ? 5 : 1;
        await this.buscaContrato(tentativas);
        this.loadingDocs = false;
      }
    },
    async getFirstPage(pdf) {
      try {
        const { guid: guidProposta } = this.propostaState;

        const payload = {
          primeiraPagina: true,
          link: [],
        };
        payload.link.push({ guid: guidProposta, valor: pdf.link });

        pdf.loading = true;
        const [response] = await documentoService.tratarExibicaoAnexo(payload);
        pdf.loading = false;

        let resultado;
        if (response.paginas) {
          resultado = response.paginas.base64;
        } else {
          resultado = response.base64;
        }

        return resultado;
      } catch (error) {
        this.loadingDocs = false;
        return this.$root.$snackBar.open({
          color: "error",
          message: error.message,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header-height {
  padding: 20px;
  height: 70px;
}
</style>
