<template>
  <div>
    <v-app-bar color="primary" dark fixed>
      <v-container class="d-flex align-center pa-0">
        <v-btn class="mr-3" small icon dark @click="$emit('closedialog')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title class="mt-1">
          <div>
            <h1 class="title font-weight-bold mb-1" style="line-height: 1">
              Validação de Dados
            </h1>
            <h3 class="caption ma-0" style="line-height: 1">
              Transmitir Proposta
            </h3>
          </div>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="validarProposta">
          <v-icon>mdi-reload</v-icon>
        </v-btn>
      </v-container>
    </v-app-bar>

    <div class="header-height"></div>

    <div v-if="loadingDatas" class="d-flex justify-center mt-10 pa-10">
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
    </div>

    <div v-else>
      <modal-confirmation
        v-if="propostaDuplicada"
        :show="cancelarPropostaDuplicada.showConfirm"
        name="cancelar"
        :loading="cancelarPropostaDuplicada.loading"
        :title="cancelarPropostaDuplicada.title"
        :text="cancelarPropostaDuplicada.text"
        :buttonConfirm="cancelarPropostaDuplicada.confirm"
        :buttonCancel="cancelarPropostaDuplicada.cancel"
        :width="500"
        :persistent="true"
        @close="cancelarPropostaDuplicada.callAfterCancel"
        @confirm="cancelarPropostaDuplicada.callAfterConfirm"
      />
      <pendente
        v-else-if="!prontaTransmissao"
        :pendenciasPreenchimento="pendenciasPreenchimento"
        :erros="erros"
      />
      <concluido
        v-else
        @concluir="$emit('closedialog')"
      />
    </div>

  </div>
</template>

<script>
import propostaService from '@/services/propostaService';
import ModalConfirmation from "@/components/ModalConfirmation.vue";
import { mapState } from 'vuex';
import Pendente from './Pendente.vue';
import Concluido from './Concluido.vue';
import propostaPMEService from '@/services/propostaPMEService';

export default {
  components: {
    Pendente,
    Concluido,
    ModalConfirmation,
  },
  props: {
    matchConversao: {
      default: [],
    },
  },
  data() {
    return {
      propostaDuplicada: null,
      cancelarPropostaDuplicada: {
        showConfirm: false,
        title: "",
        text: "",
        cancel: "",
        confirm: "",
        callAfterConfirm: () => {},
        callAfterCancel: () => {},
      },
      loadingDatas: false,
      prontaTransmissao: false,
      pendenciasPreenchimento: [],
      erros: [],
    };
  },
  computed: {
    ...mapState({
      propostaState: (state) => state.PME,
    }),
  },
  async mounted() {
    await this.validarProposta();
    await this.showModal();
  },
  methods: {
    async validarProposta() {
      try {
        this.loadingDatas = true;
        const { guid: guidProposta } = this.propostaState;       
        const data = await propostaPMEService.validarPropostaPME(guidProposta)
        if (!data) {
          this.$root.$snackBar.open({
            color: 'error',
            message: 'ERRO!',
          });
          return;
        }
        this.prontaTransmissao = data.prontaTransmissao;
        this.pendenciasPreenchimento = data.pendenciasPreenchimento;
        this.erros = data.erros;

        if (data.existePropostaDuplicada) {
          this.propostaDuplicada = data.proposta;
        }
      } catch (error) {
        this.$root.$snackBar.open({
          color: 'error',
          message: 'Erro ao transmitir, tente novamente mais tarde',
        });
      } finally {
        this.loadingDatas = false;
      }
    },
    showModal() {
      if (!this.propostaDuplicada) {
        this.cancelarPropostaDuplicada.showConfirm = false;
      } else if (
        this.propostaDuplicada.status === 'FILA_TRANSMISSAO'
        || (this.propostaDuplicada.status === 'EM_ANALISE' && this.propostaDuplicada.etapa === 'em_analise')
      ) {
        this.cancelarPropostaDuplicada.showConfirm = true;
        this.cancelarPropostaDuplicada.cancel = "Retornar Para Proposta";
        this.cancelarPropostaDuplicada.callAfterConfirm = this.solicitarCancelamentoProposta;
        this.cancelarPropostaDuplicada.callAfterCancel = this.retornarProposta;
        this.cancelarPropostaDuplicada.text = `
          Já existe uma proposta em análise para esse beneficiário (${this.propostaDuplicada.nrProposta}).
          Caso queira prosseguir com a transmissão, a mesma precisa estar devolvida. Deseja solicitar a devolução?
        `;
        this.cancelarPropostaDuplicada.confirm = "Solicitar devolução";
      } else if (this.propostaDuplicada.status === 'EM_ANALISE' && this.propostaDuplicada.etapa === 'solicitado_devolucao') {
        this.$root.$snackBar.open({
          color: 'error',
          message: `Já existe uma solicitação para devolução de proposta realizada (Proposta ${this.propostaDuplicada.nrProposta}). Por favor, aguarde a conclusão.`,
        });
        this.$emit('closedialog');
      }
    },
    retornarProposta() {
      this.propostaDuplicada = null;
      this.$emit('closedialog');
    },
    async solicitarCancelamentoProposta() {
      const textoConfirmacao = `Confirma a solicitação da devolução da proposta ${this.propostaDuplicada.nrProposta}?`;
      const resp = await this.$root.$confirmdialog.open("Solicitar devolução", textoConfirmacao);

      if (!resp) return;

      await propostaService.solicitaDevolucaoPropostaDuplicada(this.propostaDuplicada.guid);

      const textoDevolucao = `A sua solicitação de devolução foi efetuada, em até 1 dia útil a proposta ${this.propostaDuplicada.nrProposta} será devolvida.`;
      this.$root.$snackBar.open({ color: 'success', message: textoDevolucao });
      this.$emit('closedialog');
    },
  },
};

</script>

<style lang="scss" scoped>

  .header-height {
    padding: 20px;
    height: 63px;
  }

</style>
